@import url(https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap);
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

body {
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 88%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 84%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 76%;
  }
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.page_container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.color1 {
  color: #1fb8b3;
}

.color2 {
  color: #ffffff;
}

.pointer {
  cursor: pointer;
}

.faq_container {
  padding: 5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: #080b2c;
}

.custom_accordion {
  
  border-width: 1;
  border-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

#content {
  line-height: 22px;
}

.logo_container {
  height: 122px;
}

.btn {
  background: linear-gradient(175.07deg, #cf027e -9.38%, #510777 129.5%);
  }

.shadow{
  text-shadow: 0 0 40px #880e91, 0 0 50px #880e91, 0 0 60px #880e91, 0 0 70px #880e91, 0 0 80px #880e91;
}


.header{
  font-family: 'Saira Stencil One', cursive;
  color: #ffffff;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  margin-top: 50px;

}

.back a{
  color: #ffffff;
  font-size: small;
}

.faqheader{
  font-family: 'Saira Stencil One', cursive;
  color: #ffffff;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  margin-bottom: 50px;
}

.body_slider {
  padding: 8rem 0rem 0rem;
}

@media screen and (max-width: 768px) {
  .header{
    font-size: 60px;
  }
  .body_slider {
    padding: 2rem 0rem 5rem 0rem;
  }
}
@media screen and (max-width: 525px) {
  .header{
    font-size: 40px;
}
}
.bottom_ {
  max-width: 1000px;
  margin: auto;
}
/*# sourceMappingURL=styles.css.map */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(/static/media/font.617a0a92.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

